<template>
  <AdminSingleCard showLogo>
    <template v-slot:title>
      <p class="login-title">ログイン</p>
    </template>

    <template v-slot:contents>
      <p class="login-email-title">メールアドレス</p>
      <input class="email" type="email" v-model="email" />

      <p class="login-password-title">パスワード</p>
      <input class="password" type="password" v-model="password"/>
    </template>

    <template v-slot:button>
      <WideButton
        class="login-button"
        :label="'送信'"
        :disabled="(!email || !password)"
        @click="$_logIn"
      />
    </template>

    <template v-slot:append>
      <p
        class="forgot-password"
        @click="$router.push('/admin/send_reset_password')"
      >
        パスワードをお忘れの方&gt;
      </p> 
    </template>
  </AdminSingleCard>
</template>

<script>
import WideButton from '@/components/atoms/common/AppWideButton'
import AdminSingleCard from '@/templates/admin/AppAdminSingleCard'
import { auth, signInWithEmailAndPassword } from '@/firebase/config'
import { showInfoPopupAlert } from '@/helper/common'
import { getUser } from '@/helper/firestore/user'
import { USER_TYPE } from '@/constants/common'

export default {
  name: 'TheLogIn',
  components: {
    AdminSingleCard,
    WideButton
  },
  data() {
    return {
      email: '',
      password: '',
    }
  },
  mounted: function () {
    this.$store.dispatch('loadingMask/hideLoadingMask')
  },
  methods: {
    $_logIn: function () {
      this.$store.dispatch('loadingMask/showLoadingMask')
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then(async (userCredential) => {
          // Set User
          await this.$_setUser(userCredential.user)

          // Set Chunks
          this.$store.dispatch('place/setChunks')

          // routing after login
          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect)
          } else {
            this.$router.push('/admin/space_list')
          }
        })
        .catch((error) => {
          const errorMessage = this.$_getErrorMessage(error)
          showInfoPopupAlert(errorMessage)
        }).finally(() => {
          this.$store.dispatch('loadingMask/hideLoadingMask')
        })
    },
    $_setUser: async function (user) {
      const accountData = await getUser(user.uid)
      if (accountData.type === USER_TYPE.USER.ID) {
        throw new Error(`
          ログインする権限がありません（権限: ${Object.values(USER_TYPE)
            .find(u => u.ID === accountData.type).TEXT}）
        `)
      }
      this.$store.dispatch('user/setUser', {
        uid: user.uid,
        name: accountData.name,
        type: accountData.type,
        email: user.email,
        emailVarified: user.emailVerified,
        metaData: user.metadata,
      })
    },
    $_getErrorMessage: function (error) {
      if (error.message.includes('invalid-email')) {
        return 'メールアドレス/パスワードが不正です'
      } else {
        if (error.message.includes("user-not-found")) {
          return "入力されたメールアドレスに一致するアカウントがみつかりませんでした。"
        } else if (error.message.includes("wrong-password")) {
          return "パスワードが間違っています。"
        } else {
          return error.message
        }
      }
    },
  },
}
</script>

<style lang="scss">
input {
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  border: 1px solid gray;
  border-radius: 4px;
}
.login-title {
  margin: 16px 0;
}
.login-email-title {
  text-align: left;
  margin-bottom: 8px;
  font-size: 12px;
}
.email {
  margin: 0;
}
.login-password-title {
  text-align: left;
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 12px;
}
.password {
  margin: 0;
}
.login-button {
  margin-top: 32px;
}
.forgot-password {
  color: #707070;
  text-decoration: underline;
  cursor: pointer;
}
</style>
